<template>
    <div>
        <div class="sign-grid" v-if="signbookError == false">
            <div class="sign-container">
                <div id="subscription-logo" class="pa-2">
                    <img class="subscription-mobile-img" src="/img/subscription-mobile.jpg" />
                </div>
            </div>
            <div id="iframe-wrapper" class="iframe-container pa-2">
                <iframe id="signbook" scrolling="no" frameBorder="no" width="100%" allow="microphone; camera"></iframe>
            </div>
        </div>
        <div class="height-center" v-else>
            <error></error>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SignSolvability',
        components: {},
        props: {
            signbookError: Boolean,
            signbookUrl: String,
            signbookToken: String,
            signbookMerchantLogo: String,
            nthSignbook: Object
        },
        methods: {},
    }
</script>
<style scoped>
    .sign-grid {
        text-align: center;
        margin-top: 50px;
        display: grid;
        grid-template-columns: 2fr;
        gap: 0px 0px;
        grid-template-areas: "sign-container" "iframe-container";
        position: relative;
        justify-content: center;
        text-align: center;
    }

    .sign-container {
        grid-area: sign-container;
        text-align: center;
        font-size: 20px;
        padding-right: 10px;
        padding-left: 10px;
    }

    .subscription-mobile-img {
        text-align: center;
        margin-bottom: 20px;
        width: 80px;
        height: 80px;
    }

    .iframe-container {
        grid-area: iframe-container;
        align-self: start;
        text-align: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background-color: #eee;
    }

    .iframe-container iframe {
        display: block;
        width: 100%;
        height: 70vh;
        border: none;
    }

    @media only screen and (max-width: 600px) {
        #subscription-logo {
            display: none;
        }
    }
</style>